import axios from "axios";
import { apiUrl, publicApiUrl } from '@/constants/config'
import router from "@/router";
import { i18n } from "@/utils/i18n";
import { isObject } from "lodash";
const securedAxios = axios.create({
  baseURL: apiUrl,
  withCredentials: false, //XXXXXX
  headers: {
    "Content-Type": "application/json"
  }
});

const plainAxiosIsecuredAnstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

export const createPublicApiAxios = (ppt) => axios.create({
  baseURL: publicApiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'pp-t': ppt,
    'app': 'public'

  }
})

// securedAxios.defaults.params = {};
securedAxios.interceptors.request.use(config => {
  // const regex = /((?<p1>([0-9]+)),(?<p2>([0-9]+)))/gim;
  // let config_data = regex.exec(config.data);
  //! lng : en
  // while (config_data) {
  //   config.data = config.data.replace(`${config_data.groups.p1},${config_data.groups.p2}`, `${config_data.groups.p1}.${config_data.groups.p2}`);
  //   config_data = regex.exec(config.data);
  // }

  const regex = /((?<p1>([0-9]+)),(?<p2>([0-9]+)))/gim;
  if (config.data && !(config.data instanceof FormData)) {
    let config_data = JSON.stringify(config.data);

    config_data.match(regex)?.forEach(match => {
      config_data = config_data.replace(match, `${match.split(",")[0]}.${match.split(",")[1]}`);
    });

    config.data = JSON.parse(config_data);
  }

  if(isObject(config.params)){
    config.params["lng"] = i18n.locale
  } else {
    config.params = {
      lng : i18n.locale
    }
  }

  const method = config.method.toUpperCase();
  if (method !== "OPTIONS") {
    config.headers = {
      ...config.headers,
      tknk: localStorage.getItem("token")
    };
  }
  return config;
});

securedAxios.interceptors.response.use(null, error => {
  if (
    error.response &&
    error.response.config &&
    error.response.status === 401 /// XXXXX We will fill this
  ) {
    delete localStorage.token
    delete localStorage.user

    return router.push({ name: 'LoginPage' })

    return plainAxiosInstance
      .post("/refresh_token", {}, { headers: { tknk: localStorage.token } })
      .then(response => {
        localStorage.token = response.data.token;
        // LocalStorage Setting Here...
        localStorage.signedIn = true;

        let retryConfig = error.response.config;
        retryConfig.headers.tknk = localStorage.token;
        return plainAxiosInstance.request(retryConfig);
      })
      .catch(error => {
        delete localStorage.token;
        delete localStorage.signedIn;

        //location.replace("/");
        return Promise.reject(error);
      });
  } else {
    return Promise.reject(error);
  }
});

export { securedAxios };
